import { m } from 'framer-motion';
import PropTypes from 'prop-types';
// @mui
import Badge, { badgeClasses } from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// components
import { useEffect, useState } from 'react';
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { baseURL } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import axios from 'src/utils/axios';
import SettingDialog from './Dialog';
// hooks

// ----------------------------------------------------------------------
export default function SettingsButton({ sx }) {
  const settings = useSettingsContext();
  const confirm = useBoolean(); 

 


  return (
    <>
      <Badge
        color="error"
        variant="dot"
        invisible={!settings.canReset}
        sx={{
          [`& .${badgeClasses.badge}`]: {
            top: 8,
            right: 8,
          },
          ...sx,
        }}
      >
        <Box
          component={m.div}
          animate={{
            rotate: [0, settings.open ? 0 : 360],
          }}
          transition={{
            duration: 12,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            aria-label="settings"
            onClick={confirm.onTrue} // Open the dialog on click
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <Iconify icon="solar:settings-bold-duotone" width={24} />
          </IconButton>
        </Box>
      </Badge>
      <SettingDialog 
      open={confirm.value}
       onClose={confirm.onFalse}
        /> 
    </>
  );
}

SettingsButton.propTypes = {
  sx: PropTypes.object,
};
