// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const baseURL = `https://menu-api.truffleiot.com/api`

export const FIREBASE_API = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: 'AIzaSyAiQfaHaETBUi4n3McVAICFvMhrq8Zp8Bs',
  authDomain: 'truffle-df214.firebaseapp.com',
  projectId: 'truffle-df214',
  storageBucket: 'truffle-df214.firebasestorage.app',
  messagingSenderId: '771271693645',
  appId: '1:771271693645:web:d1e6ba44c6035f4e56d304',
  measurementId: 'G-9ENV5D5P0J',
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL

export const PATH_LOGIN = "/auth/jwt/login?returnTo=%2Fdashboard"; 
export const PATH_AFTER_LOGIN = paths.dashboard.root; 
export const PATH_AFTER_LINKED_RESTRAUNT = "/auth/jwt/login/linking?returnTo=%2Fdashboard";

