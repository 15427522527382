import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// config
import { baseURL, PATH_AFTER_LOGIN } from 'src/config-global';
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import axios from 'src/utils/axios';
import { useAuthContext } from 'src/auth/hooks';

export default function ConnectRestaurantView() {
  const { linkDispatch } = useAuthContext();

  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState('');

  // Retrieve 'returnTo' parameter from URL
  const searchParams = new URLSearchParams(window.location.search);
  const returnTo = searchParams.get('returnTo');

  // 1. Update the Validation Schema
  const LoginSchema = Yup.object().shape({
    // restaurant_id: Yup.string()
    //     .required('Restaurant ID is required')
    //     .trim(),
    // access_token: Yup.string()
    //     .required('Access token is required')
    //     .trim(),
    // base_url: Yup.string()
    //     .notRequired()
    //     .url('Base URL must be a valid URL')
    //     .trim(),
  });

  const defaultValues = {
    // restaurant_id: localStorage.getItem('restaurant_id') || '',
    // access_token: localStorage.getItem('access_token') || '',
    // base_url: localStorage.getItem('base_url') || '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const apiData = {
      restaurant_id: data.restaurant_id,
      base_url: data.base_url,
      name: data.name,
      access_token: data.access_token,
    };
    console.log(apiData, 'my data');
    try {
      const response = await axios.post(
        `${baseURL}/update-profile`,
        apiData
      );
      console.log('🚀 ~ onSubmit ~ response:', response);
      if (response.status === 200) {
        const getUserDetails=JSON.parse(sessionStorage.getItem('userDetails'));
        console.log(getUserDetails, 'my user details');
        sessionStorage.setItem('userDetails', JSON.stringify(apiData,getUserDetails));
        linkDispatch?.(getUserDetails);
      }
      router.push(PATH_AFTER_LOGIN);
    } catch (error) {
      console.error('Connection error:', error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message || 'Connection failed');
    }
  });

  const renderHead = (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <Typography variant="h4">Connect to Restaurant</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2}>
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField name="name" label="Name" autoFocus />
      <RHFTextField name="restaurant_id" label="Restaurant/Branch ID" autoFocus />
      <RHFTextField name="access_token" label="Truffle Token" type="password" />
      <RHFTextField name="base_url" label="Base URL" placeholder="https://api.yourrestaurant.com" />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Connect
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {renderForm}
    </FormProvider>
  );
}
