import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { PATH_AFTER_LINKED_RESTRAUNT, PATH_AFTER_LOGIN, PATH_LOGIN } from 'src/config-global';
import { useRouter } from 'src/routes/hooks';
import { AuthContext } from './auth-context';
import { setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        loading: false,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user, // Standardize to 'user'
      };
    case 'REGISTER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router=useRouter()
  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const user = JSON.parse(sessionStorage.getItem('userDetails'));

      if (accessToken && user.restaurant_id) {
        setSession(accessToken);

        // const response = await axios.get(endpoints.auth.me);

        // const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const signup = useCallback(async (email, password, name) => {
    const data = { email, password ,name};
    const response = await axios.post(endpoints.auth.register, data);
    console.log('🚀 ~ login ~ response:', response);
    console.log(response.data, 'here is the token');
    const { access_token, user } = response.data;
    setSession(access_token);
    router.push(PATH_LOGIN);
  }, [router]);



  const login = useCallback(async (email, password) => {
    const data = { email, password };

    const response = await axios.post(endpoints.auth.login, data);
    console.log('🚀 ~ login ~ response:', response);
    // Assuming response.data contains { accessToken, user }
    console.log(response.data, 'here is the token');
    const { access_token, user } = response.data;

    setSession(access_token);
    if (user.restaurant_id){
      dispatch({
        type: 'LOGIN',
        payload: { user },
      });
      sessionStorage.setItem('userDetails', JSON.stringify(user));
    }else{
      sessionStorage.setItem('userDetails', JSON.stringify(user));
    }
    const redirectPath1 = user.restaurant_id ? PATH_AFTER_LOGIN : PATH_AFTER_LINKED_RESTRAUNT;
    await router.push(redirectPath1);
  }, [router]);
  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);
    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);
  const linkDispatch = useCallback(async (linkdetail) => {
    dispatch({
      type: 'LOGIN',
      payload: { user: linkdetail },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      linkDispatch,
      signup,
      login,
      register,
      logout,
    }),
    [login, logout, signup , linkDispatch, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
