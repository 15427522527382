import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
// @mui
import {
  Button,
  Card,
  Container,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
// routes
import { useRouter } from 'src/routes/hooks';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
//
import { baseURL } from 'src/config-global';
import axios from 'src/utils/axios';
import UserTableFiltersResult from '../user-table-filters-result';
import UserTableRow from '../user-table-row';
import AddEditUser from './add-user';

// ----------------------------------------------------------------------

const USER_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  // Add more statuses if needed
];

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 220 },
  { id: 'email', label: 'Email', width: 220 },
  { id: 'role', label: 'Role', width: 180 },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function UserListView() {
  const table = useTable();
  const settings = useSettingsContext();
  const router = useRouter();
  const confirm = useBoolean();
  const open = useBoolean();

  const [tableData, setTableData] = useState([]);
  console.log("🚀 ~ UserListView ~ tableData:", tableData);

  const [filters, setFilters] = useState(defaultFilters);
  const [currentUser, setCurrentUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null); // Track the user selected for deletion

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/users`);
      console.log("🚀 ~ fetchUsers ~ response:", response);

      // Map the payload to match table columns
      const users = response.data.payload.map(user => ({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.roles.join(', '),
        status: user.active === 1 ? 'Active' : 'Inactive',
      }));

      setTableData(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleDeleteRow = useCallback(async () => {
    if (userToDelete !== null) {
      try {
        await axios.delete(`${baseURL}/users/${userToDelete}`);
        const deleteRow = tableData.filter((row) => row.id !== userToDelete);
        setTableData(deleteRow);
        table.onUpdatePageDeleteRow(dataInPage.length);
        setUserToDelete(null); 
        confirm.onFalse();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  }, [userToDelete, dataInPage.length, table, tableData, confirm]);



  // const handleEditRow = useCallback(
  //   (id) => {
  //     const userToEdit = tableData.find((row) => row.id === id);
  //     console.log("🚀 ~ UserListView ~ userToEdit:", userToEdit);
  //     setCurrentUser(userToEdit); // Set the user to edit
  //     open.onTrue(); // Open the modal
  //   },
  //   [tableData, open]
  // );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleConfirmDelete = (id) => {
    setUserToDelete(id); // Set the selected user for deletion
    confirm.onTrue(); // Open the confirm modal
  };

  const handleCreateUser = () => {
    setCurrentUser(null); // Clear current user for new entry
    open.onTrue(); // Open the modal
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Users"
          links={[
            // { name: 'Dashboard', href: paths.dashboard.root },
            // { name: 'User', href: paths.dashboard.user.root },
            { name: '' },
          ]}
          action={
            <Button
              onClick={handleCreateUser}
              // component={RouterLink}
              // href={paths.dashboard.user.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              Create User
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          {/* Filters and Toolbar can be added here if needed */}

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleConfirmDelete(row.id)} // Confirm delete first
                        // onEditRow={() => handleEditRow(row.id)}
                        reload={fetchUsers}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <AddEditUser
      reload={fetchUsers}
        open={open.value}
        onClose={() => open.onFalse()}
        isEdit={!!currentUser}
        item={currentUser || {}}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete User"
        content="Are you sure you want to delete this user?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status.toLowerCase() === status.toLowerCase());
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.some(r => user.role.includes(r)));
  }

  return inputData;
}
