import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { baseURL } from 'src/config-global';
import axios from 'src/utils/axios';

export default function AddUser({ open, onClose , reload }) {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const password = useBoolean();

    // Validation schema
    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('name is required'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        // password: Yup.string()
        //     .required('Password is required')
        //     .min(8, 'Password must be at least 8 characters long')
        //     .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
        //     .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must include at least one special character'),
    });

    const defaultValues = useMemo(() => ({
        name: '',
        email: '',
        password: '',
    }), []);

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const { reset, handleSubmit, watch, formState: { errors } } = methods;
    const values = watch();

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const onSubmit = handleSubmit(async (value) => {
        setLoading(true);
        const apiData = {
            name: value.name,
            email: value.email,
            password: value.password,
        };
        try {
            await axios.post(
                `${baseURL}/users`,
                apiData,
            );
            enqueueSnackbar('User created successfully', { variant: 'success' });
            setLoading(false);
            onClose();
            reset()
            reload();

        } catch (error) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setLoading(false);
        }
    });

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ p: 3, background: '#F4F6F8', mb: 2 }}>
                    Create User
                    <Iconify icon="ri:close-line" style={{ position: 'absolute', right: 30, cursor: 'pointer' }} onClick={onClose} />
                </DialogTitle>

                <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
                    <Stack spacing={2}>
                        <RHFTextField name="name" label="Name" error={!!errors.name} helperText={errors.name?.message} />
                        <RHFTextField name="email" label="Email Address" error={!!errors.email} helperText={errors.email?.message} />
                        <RHFTextField
                            name="password"
                            label="Password"
                            type={password.value ? 'text' : 'password'}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={password.onToggle} edge="end">
                                            <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
                        Create
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

AddUser.propTypes = {
    reload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
