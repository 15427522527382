import PropTypes from 'prop-types';
// @mui
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
// components
import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { baseURL } from 'src/config-global';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { id, name, email, role, status } = row;
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(status === 'Active');
  }, [status]);

  const handleStatusChange = async (event, checked) => {
    const previousActive = active;
    setActive(checked);

    try {
      const response = await axios.get(`${baseURL}/users/${id}/toggle-active`);
      if (!response.data.metadata.success) {
        // Revert the state if the API call failed
        setActive(previousActive);
        console.error('Failed to update status');
      }
    } catch (error) {
      // Revert the state if the API call failed
      setActive(previousActive);
      console.error('Error updating status:', error);
    }
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemText primary={name} primaryTypographyProps={{ typography: 'body2' }} />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Label
          sx={{ mt: 1, mr: 1 }}
          variant="soft"
          color={active ? 'success' : 'default'}
        >
          {active ? 'Active' : 'Inactive'}
        </Label>
        <Switch checked={active} onChange={handleStatusChange} />
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Tooltip title="Delete" placement="top" arrow>
          <IconButton color="error" onClick={onDeleteRow}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};
