import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
// components
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import axios from 'src/utils/axios';
import { baseURL } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

// Define schema for validation using yup
const schema = yup.object().shape({
    restaurant_id: yup.string().required('Restaurant/Branch ID is required'),
    base_url: yup.string().url('Base URL must be a valid URL').required('Base URL is required'),
    name: yup.string().required('Name is required'),
    access_token: yup.string().required('Access Token is required'),
});

export default function SettingDialog({ open, onClose }) {
    const { linkDispatch } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(schema),
    });
    const { handleSubmit, control, reset, setError } = methods;

    // Fetch profile data when dialog opens
    useEffect(() => {
        if (open) {
            setLoading(true);
            axios.get(`${baseURL}/profile`)
                .then((response) => {
                    console.log("🚀 ~ .then ~ response:", response)
                    if (response.status === 200) {
                        reset({
                            restaurant_id: response.data.user.restaurant_id,
                            base_url: response.data.user.base_url,
                            name: response.data.user.name,
                            access_token: response.data.user.access_token,
                        });
                    } else {
                        enqueueSnackbar('Failed to load profile data', { variant: 'error' });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching profile data:', error);
                    enqueueSnackbar('Error fetching profile data', { variant: 'error' });
                })
                .finally(() => setLoading(false));
        }
    }, [open, reset, enqueueSnackbar]);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const apiData = {
            restaurant_id: data.restaurant_id,
            base_url: data.base_url,
            name: data.name,
            access_token: data.access_token,
        };

        try {
            const response = await axios.post(`${baseURL}/update-profile`, apiData);
            if (response.status === 200) {
                sessionStorage.setItem('userDetails', JSON.stringify(apiData));
                linkDispatch?.(apiData);
                onClose();
                reset();
                enqueueSnackbar('Profile updated successfully', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to update profile', { variant: 'error' });
            }
        } catch (error) {
            console.error('Connection error:', error);
            enqueueSnackbar('Error updating profile', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ p: 3, mb: 2 }}>
                Update your Link account
                <Iconify
                    icon="ri:close-line"
                    style={{ position: 'absolute', right: 30, cursor: 'pointer' }}
                    onClick={onClose}
                />
            </DialogTitle>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent dividers sx={{ pt: 1, pb: 2, border: 'none' }}>
                        <Controller
                            name="name"
                            defaultValue="" // Set default value to an empty string
                            control={control}
                            render={({ field }) => (
                                <RHFTextField
                                    {...field}
                                    label="Nick Name"
                                    sx={{ mb: 3 }}
                                    error={!!methods.formState.errors.name}
                                    helperText={methods.formState.errors.name?.message}
                                />
                            )}
                        />
                        <Controller
                            name="restaurant_id"
                             defaultValue="" // Set default value to an empty string

                            control={control}
                            render={({ field }) => (
                                <RHFTextField
                                    {...field}
                                    label="Restaurant/Branch ID"
                                    sx={{ mb: 3 }}
                                    error={!!methods.formState.errors.restaurant_id}
                                    helperText={methods.formState.errors.restaurant_id?.message}
                                />
                            )}
                        />
                        <Controller
                            name="access_token"
                            defaultValue="" // Set default value to an empty string

                            control={control}
                            render={({ field }) => (
                                <RHFTextField
                                    {...field}
                                    label="Truffle Token"
                                    type="password"
                                    sx={{ mb: 3 }}
                                    error={!!methods.formState.errors.access_token}
                                    helperText={methods.formState.errors.access_token?.message}
                                />
                            )}
                        />
                        <Controller
                            defaultValue="" // Set default value to an empty string

                            name="base_url"
                            control={control}
                            render={({ field }) => (
                                <RHFTextField
                                    {...field}
                                    label="Base URL"
                                    sx={{ mb: 3 }}
                                    error={!!methods.formState.errors.base_url}
                                    helperText={methods.formState.errors.base_url?.message}
                                />
                            )}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}

SettingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
