import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { UserListView } from 'src/sections/user/view';
import { LoadingScreen } from 'src/components/loading-screen';
// sections

// ----------------------------------------------------------------------

export default function UsersList() {
  const [user, setuser] = useState([]);

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      {loading ? <LoadingScreen /> : <UserListView user={user} />}
    </>
  );
}
